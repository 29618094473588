.placer {
  width: 200px;
  height: 400px;

  box-sizing: border-box;
  background: rgb(70, 192, 70);
  position: relative;
}
.container {
  position: relative;
}
.bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
