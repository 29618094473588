.table {
  table {
    // border-bottom: 1px solid #eee;
    // border: 1px solid red;
    border-collapse: collapse;
    th {
      text-align: right;
    }

    td:nth-child(1) {
      width: 135px;
      font-weight: 700;
      font-size: 1.2em;
      color: #a70207;
    }
    td:nth-child(2) {
      width: 75px;
    }
    td:first-child {
      text-align: right;
    }
    td:not(:first-child) {
      text-align: right;
    }
  }
}
