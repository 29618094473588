.items {
  display: flex;
  flex-wrap: wrap;
}
.add {
  display: inline-block;
  color: #aaa;
  font-size: 3rem;
  padding: 1em;
  display: flex;
  align-items: center;
  //   border: 1px solid black;
  cursor: pointer;

  &:active {
    color: #777;
  }
}
