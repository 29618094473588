.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.conditions {
  display: inline-flex;
  flex-direction: column;
}
.condition {
  display: flex;
  align-items: center;
  margin: 1em 0;
}
