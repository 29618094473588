@import url("https://fonts.googleapis.com/css?family=Arimo&display=swap");
@import url("https://fonts.googleapis.com/css?family=Heebo&display=swap");
body {
  margin: 0;
  /* font-family: 'Arimo', sans-serif; */
  font-family: "Arimo", sans-serif;
  background-color: #eee;
  direction: rtl;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
