.container {
  text-align: center;
  display: inline-block;
}
.actions {
  //   display: flex;
  //   justify-content: space-between;
  margin: 0.25em 0;
}
.disabled {
  position: relative;
  filter: grayscale(1);
  pointer-events: none;
}
.disabled::after {
  position: absolute;
  content: " ";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
