.item {
  display: flex;
  align-items: center;
  // border: 1px solid blue;
  border-bottom: 1px solid #cecece;
  margin: 0.5em 0;
  padding: 0.2em;
  .head {
    display: inline-block;
    width: 40%;
    // border: 1px solid red;
    display: inline-flex;
    align-items: center;
    p {
      margin: 0 0.5em;
      font-weight: 700;
    }
  }
  .controlls {
    display: inline-block;
    white-space: nowrap;
    // border: 1px solid yellow;

    width: 40%;
    span {
      margin: 0 0.5em;
      display: inline-flex;
      align-items: center;
      label {
        margin: 0 0.5em;
      }
    }
  }
}
