.container {
  background: #e1eaf1;
}
.image img {
  max-height: 150px;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 5em;
  padding: 0.5em;
}
.controls {
  display: flex;

  justify-content: space-around;
  button {
    margin: 0 !important;
    border-radius: 0px !important;
    flex: 1;
  }
}
