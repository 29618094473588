.stage {
  border-radius: 20px;
  box-shadow: 2px 2px 5px black;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 350px;
  margin: 1em;
  // overflow: hidden;
  .body {
    padding: 0.5em;
  }
  .header {
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.5em;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #efefef;
    h3 {
      margin: 0 1em;
      font-style: italic;
    }
  }
}
