.items {
  display: flex;
}

.area {
  text-align: center;
  margin-top: 0.5em;
  p {
    margin: 0;
    font-weight: 700;
    span {
      color: #d01919;
      display: inline-block;
    }
  }

  textarea {
    width: 100%;
    resize: vertical;
    height: 280px;
  }
}
.preview {
  list-style-type: none;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  li {
    background: #efefef;
    padding: 0.5em;
    margin: 0.25em;
    border-radius: 15px;
    text-align: center;
  }
}
.condition {
  display: flex;
  align-items: center;
  h3 {
    margin: 0;
    direction: ltr;
  }
}
