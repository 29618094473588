.blocker {
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: not-allowed;
  z-index: 1000;
}

.container {
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  right: 50%;
  /* border: 5px solid black; */
  min-width: 90%;
  width: 100%;
  transform: translateX(50%);
}
/* @media (max-width: 1100px) {
  .container {
    width: 100%;
  }
} */
.field {
  margin: 0.5em 1em;
}

.field label {
  display: block;
}
.desc {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* height: 275px; */
  height: 100%;
  width: 200px;
}
.desc textarea {
  width: 100%;
  height: 100%;
  resize: vertical;
}
.hide {
  display: none;
}
.action {
  text-align: right;
}
