.container {
  direction: rtl;
}
.container img {
  max-height: 50px;
  max-width: 150px;
}
.container nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
.container nav span {
  margin: 0 1em;
  background: #9fa6ac;
  color: white;
  border-right: 1px solid #999;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  padding: 0.7em 1em;
  flex: 1;
  margin: 0;
}
.container span:hover,
nav .active {
  background: #ccc;
}
.table {
  /* display: inline-block; */
  /* direction: ltr; */
  overflow: auto;
  max-height: 500px;
  margin-bottom: 2em;
}
.table tr td:first-child {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
}
.table tr td:first-child:active {
  text-decoration: underline;
  cursor: pointer;
  color: #d01919;
  user-select: none;
}
