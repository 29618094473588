.container {
  text-align: center;
  // border: 5px solid red;
  height: 100%;
  display: flex;
  flex-direction: column;

  // align-items: center;
}
.wraper {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  // margin-top: 1em;
  overflow: hidden;
  overflow-y: auto;
}
.card {
  // display: none !important;
  display: flex;
  flex: 1;
  max-width: 150px;

  background: white;
  margin: 0.7em;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  img {
    max-height: 150px;
    max-width: 150px;
  }
  p {
    text-align: center;
    font-weight: 700;
  }
}
.selected {
  border: 2px solid red;
}
