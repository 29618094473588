.main {
  width: 80%;
  margin: 0 auto;
  /* background:white; */
}
.main h1 {
  text-align: center;
}
.noteContainer {
  position: fixed;
  top: 0px;
  width: 300px;
  margin: auto;
  overflow: hidden;
  text-align: center;
}
@media (max-width: 1100px) {
  .main {
    width: 100%;
  }
}
