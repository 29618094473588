.container {
  background: #efefef;
  padding: 0.5em 1em;
  border-radius: 15px;
}
.main {
  display: flex;
}
.new {
  display: inline-flex;
  flex-direction: column;
}
.browser {
  padding-right: 5em;
}
.btns {
  display: flex;
  :first-child {
    flex: 1;
  }
}
.name {
  color: #d01919;
  font-weight: 700;
}
