.container {
  border: 1px solid #efefef;
  display: inline-block;
  max-height: 100%;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  overflow: hidden;
  button {
    width: 100%;
    border-radius: 0 !important;
  }
}
.list {
  max-height: 550px;
  overflow-y: scroll;
  max-width: 250px;
  list-style: none;
  padding: 0.75em;
  margin: 0;
  li {
    margin: 0.25em 0;
    padding: 0.25em;
    cursor: pointer;
    img {
      max-height: 20px;
      margin-left: 0.5em;
    }
    &:hover {
      background: linear-gradient(240deg, #ccccccff, #cccccc00);
    }
    &.selected {
      // font-weight: 700;
      // border: 1px solid #ccc;
      background: linear-gradient(240deg, #ccccccff, #cccccc00);
      :global {
        .icon {
          color: #fefefe !important;
        }
      }
    }
  }
}
