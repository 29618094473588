.section {
  width: 100%;
  margin: 10px;
  transition: 0.5s;
}
.content {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
}
.sectionTitle {
  background: linear-gradient(-90deg, #9fa6ac 20%, #fff 95%);
  /* background: #f2f2f2; */
  color: white;
  text-align: right;
  /* border-bottom: 1px solid black; */
  padding: 0.5em;
  font-family: heebo;
  position: relative;
  user-select: none;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 10px;
    background: #a70207;
    right: -0.5em;
    top: 0;
  }
}
