.card {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05);
  display: inline-flex;
  flex-direction: column;
  margin: 1em;
  border-radius: 15px;
  flex: 1 0 auto;
  max-width: 150px;
  overflow: hidden;
  // justify-content: space-between;
  // padding-bottom: 0.5em;
  img {
    max-height: 150px;
  }
  .imageIcon {
    text-align: center;
    height: 150px;
    width: 150px;
    color: #efefef;
  }
  a {
    text-align: center;
  }
}
