.container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.view {
  position: relative;
  display: inline-block;
  margin: 0 1em;
}
.bg {
  max-height: 400px;
}
.controlBar {
  // border: 5px solid black;
  margin: 0 5em;
}
.viewTitlePreview {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 5em;
  color: rgba(0, 0, 0, 0.5);
}
