.item {
  position: absolute;

  border: 3px dashed black;
  cursor: pointer;
  z-index: 10;
  box-sizing: border-box;
  direction: ltr;
  user-select: none;
  &:active {
    background: rgba(0, 0, 0, 0.1);
    cursor: move;
  }
  img {
    height: 100%;
    width: 100%;
  }
}

.resizer {
  display: block;
  position: relative;
  background: black;
  top: 100%;
  left: 100%;
  width: 9px;
  height: 9px;
  transform: translate(-50%, -50%);
  cursor: se-resize;
}
.disabled {
  border: none;
  pointer-events: none;
  .resizer {
    display: none !important;
  }
}
