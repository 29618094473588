@import url(https://fonts.googleapis.com/css?family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css?family=Heebo&display=swap);
body {
  margin: 0;
  /* font-family: 'Arimo', sans-serif; */
  font-family: "Arimo", sans-serif;
  background-color: #eee;
  direction: rtl;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Panel_panel__3xXYl {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.Navbar_nav__2oMlM {
  top: 0;
  position: sticky;
  z-index: 100;
  background: #a70207;
  display: flex;
  justify-content: space-between;
}
.Navbar_nav__2oMlM > div {
  background: white;
  align-self: center;
  padding: 0 0.5em;
  margin: 0 1em;
  box-shadow: 0px 0px 5px black;
  border-radius: 10px;
}
.Navbar_nav__2oMlM nav {
  display: flex;
  justify-content: center;
  /* flex: 1; */
}
.Navbar_nav__2oMlM li.Navbar_navItem__2fj5p {
  list-style: none;
  padding: 10px 30px;
  color: #ddd;
  cursor: pointer;
  /* transition: 0.2s; */
}
.Navbar_nav__2oMlM li:hover.Navbar_navItem__2fj5p,
.Navbar_nav__2oMlM a.Navbar_active__1FOTf li {
  background: white;
  color: black;
}
.Navbar_nav__2oMlM a {
  color: inherit;
  text-decoration: none;
}

.Manager_main__33Tt8 {
  width: 80%;
  margin: 0 auto;
  /* background:white; */
}
.Manager_main__33Tt8 h1 {
  text-align: center;
}
.Manager_noteContainer__2M4Qn {
  position: fixed;
  top: 0px;
  width: 300px;
  margin: auto;
  overflow: hidden;
  text-align: center;
}
@media (max-width: 1100px) {
  .Manager_main__33Tt8 {
    width: 100%;
  }
}

.Hub_container__ysgEC nav {
  position: sticky;
  top: 0;
}

.UploadImage_container__2WJuk{background:#e1eaf1}.UploadImage_image__3Olf7 img{max-height:150px}.UploadImage_icon__11kxj{display:flex;justify-content:center;align-items:center;color:#fff;font-size:5em;padding:.5em}.UploadImage_controls__2Sff8{display:flex;justify-content:space-around}.UploadImage_controls__2Sff8 button{margin:0 !important;border-radius:0px !important;flex:1 1}
.PartForm_blocker__37P0f {
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: not-allowed;
  z-index: 1000;
}

.PartForm_container__1Jjst {
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  right: 50%;
  /* border: 5px solid black; */
  min-width: 90%;
  width: 100%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}
/* @media (max-width: 1100px) {
  .container {
    width: 100%;
  }
} */
.PartForm_field__26qfK {
  margin: 0.5em 1em;
}

.PartForm_field__26qfK label {
  display: block;
}
.PartForm_desc__1GQMg {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* height: 275px; */
  height: 100%;
  width: 200px;
}
.PartForm_desc__1GQMg textarea {
  width: 100%;
  height: 100%;
  resize: vertical;
}
.PartForm_hide__1WJyi {
  display: none;
}
.PartForm_action__3qgRl {
  text-align: right;
}

.ParamTable_table__CHl8y table{border-collapse:collapse}.ParamTable_table__CHl8y table th{text-align:right}.ParamTable_table__CHl8y table td:nth-child(1){width:135px;font-weight:700;font-size:1.2em;color:#a70207}.ParamTable_table__CHl8y table td:nth-child(2){width:75px}.ParamTable_table__CHl8y table td:first-child{text-align:right}.ParamTable_table__CHl8y table td:not(:first-child){text-align:right}
.Prameters_container__1Pl3_{width:100%;text-align:right}
.Collapsable_section__c1xiG{width:100%;margin:10px;transition:.5s}.Collapsable_content__2Arvd{padding:1rem;display:flex;flex-wrap:wrap}.Collapsable_sectionTitle__J-qgk{background:linear-gradient(-90deg, #9fa6ac 20%, #fff 95%);color:#fff;text-align:right;padding:.5em;font-family:heebo;position:relative;-webkit-user-select:none;-ms-user-select:none;user-select:none}.Collapsable_sectionTitle__J-qgk::before{content:"";position:absolute;height:100%;width:10px;background:#a70207;right:-0.5em;top:0}
.ItemFilter_wraper__2lJW_{display:flex;align-items:center;justify-content:flex-start;padding:1em 4em}.ItemFilter_wraper__2lJW_ label{margin:0 1em 0 .5em}
.PartBrowser_container__-96V_{text-align:center;height:100%;display:flex;flex-direction:column}.PartBrowser_wraper__2rsSq{display:inline-flex;flex-wrap:wrap;justify-content:center;overflow:hidden;overflow-y:auto}.PartBrowser_card__cyrCq{display:flex;flex:1 1;max-width:150px;background:#fff;margin:.7em;justify-content:space-between;flex-direction:column;box-shadow:0 2.8px 2.2px rgba(0,0,0,.02),0 6.7px 5.3px rgba(0,0,0,.028),0 12.5px 10px rgba(0,0,0,.035),0 22.3px 17.9px rgba(0,0,0,.042),0 41.8px 33.4px rgba(0,0,0,.05),0 100px 80px rgba(0,0,0,.07)}.PartBrowser_card__cyrCq img{max-height:150px;max-width:150px}.PartBrowser_card__cyrCq p{text-align:center;font-weight:700}.PartBrowser_selected__1bvWC{border:2px solid red}
.Dependencies_container__3b7k1{display:flex;height:300px;width:50%}.Dependencies_column__pknQ5{border-left:1px solid #efefef;border-right:1px solid #efefef;width:150px;flex:1 1;display:flex;flex-direction:column;justify-content:center}.Dependencies_column__pknQ5 h3{text-align:center;background:#d71921;color:#fff;margin:0}.Dependencies_column__pknQ5 .Dependencies_items__1QKbJ{flex:1 1;overflow-y:auto;padding:.25em;display:flex;flex-direction:column}.Dependencies_column__pknQ5 .Dependencies_items__1QKbJ span{font-weight:700;margin:.25em;width:50%;text-align:right}.Dependencies_column__pknQ5 button{margin:0 !important;border-radius:0px !important}
.DisplayBrowser_container__3s6ow{display:flex;justify-content:center;max-height:750px;overflow-y:auto}
.DisplayImageManager_container__3HKPY {
  direction: rtl;
}
.DisplayImageManager_container__3HKPY img {
  max-height: 50px;
  max-width: 150px;
}
.DisplayImageManager_container__3HKPY nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
.DisplayImageManager_container__3HKPY nav span {
  margin: 0 1em;
  background: #9fa6ac;
  color: white;
  border-right: 1px solid #999;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  padding: 0.7em 1em;
  flex: 1 1;
  margin: 0;
}
.DisplayImageManager_container__3HKPY span:hover,
nav .DisplayImageManager_active__36m-a {
  background: #ccc;
}
.DisplayImageManager_table__3ITJJ {
  /* display: inline-block; */
  /* direction: ltr; */
  overflow: auto;
  max-height: 500px;
  margin-bottom: 2em;
}
.DisplayImageManager_table__3ITJJ tr td:first-child {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
}
.DisplayImageManager_table__3ITJJ tr td:first-child:active {
  text-decoration: underline;
  cursor: pointer;
  color: #d01919;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.PositionEditor_placer__36aYK{width:200px;height:400px;box-sizing:border-box;background:#46c046;position:relative}.PositionEditor_container__SqaQw{position:relative}.PositionEditor_bg__1BtMv{width:100%;height:100%;top:0;left:0;position:absolute}
.Resizer_item__1GYyA{position:absolute;border:3px dashed #000;cursor:pointer;z-index:10;box-sizing:border-box;direction:ltr;-webkit-user-select:none;-ms-user-select:none;user-select:none}.Resizer_item__1GYyA:active{background:rgba(0,0,0,.1);cursor:move}.Resizer_item__1GYyA img{height:100%;width:100%}.Resizer_resizer__1mC41{display:block;position:relative;background:#000;top:100%;left:100%;width:9px;height:9px;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);cursor:se-resize}.Resizer_disabled__2ou2o{border:none;pointer-events:none}.Resizer_disabled__2ou2o .Resizer_resizer__1mC41{display:none !important}
.DisplayEditor_container__1igfH{text-align:center;display:inline-block}.DisplayEditor_actions__2Fw0b{margin:.25em 0}.DisplayEditor_disabled__wvsu_{position:relative;-webkit-filter:grayscale(1);filter:grayscale(1);pointer-events:none}.DisplayEditor_disabled__wvsu_::after{position:absolute;content:" ";width:100%;height:100%;top:0;left:0}
.Display_container__37Roo{background:#efefef;padding:.5em 1em;border-radius:15px}.Display_main__2pdPH{display:flex}.Display_new__2NlQG{display:inline-flex;flex-direction:column}.Display_browser__JMEsu{padding-right:5em}.Display_btns__iIK6R{display:flex}.Display_btns__iIK6R :first-child{flex:1 1}.Display_name__iUy8u{color:#d01919;font-weight:700}
.TypeForm_container__15jXJ{display:flex;align-items:center;flex-direction:column;justify-content:flex-start;align-items:stretch}.TypeForm_container__15jXJ button{align-self:center}.TypeForm_main__1K8hn>div,.TypeForm_media__avPI1>div{margin:0 1em}.TypeForm_media__avPI1{margin:1em;display:flex;align-items:flex-start;justify-content:flex-start;text-align:center}
.TypeManager_container__2Xylr{display:flex}.TypeManager_container__2Xylr main{flex:1 1}
.ListSelector_container__1tiQY{border:1px solid #efefef;display:inline-block;max-height:100%;box-shadow:0 2.8px 2.2px rgba(0,0,0,.02),0 6.7px 5.3px rgba(0,0,0,.028),0 12.5px 10px rgba(0,0,0,.035),0 22.3px 17.9px rgba(0,0,0,.042),0 41.8px 33.4px rgba(0,0,0,.05),0 100px 80px rgba(0,0,0,.07);border-radius:5px;overflow:hidden}.ListSelector_container__1tiQY button{width:100%;border-radius:0 !important}.ListSelector_list__1fk9T{max-height:550px;overflow-y:scroll;max-width:250px;list-style:none;padding:.75em;margin:0}.ListSelector_list__1fk9T li{margin:.25em 0;padding:.25em;cursor:pointer}.ListSelector_list__1fk9T li img{max-height:20px;margin-left:.5em}.ListSelector_list__1fk9T li:hover{background:linear-gradient(240deg, #ccccccff, #cccccc00)}.ListSelector_list__1fk9T li.ListSelector_selected__3GMKh{background:linear-gradient(240deg, #ccccccff, #cccccc00)}.ListSelector_list__1fk9T li.ListSelector_selected__3GMKh .icon{color:#fefefe !important}
.ParamForm_items__ts6KO{display:flex}.ParamForm_area__3nyWZ{text-align:center;margin-top:.5em}.ParamForm_area__3nyWZ p{margin:0;font-weight:700}.ParamForm_area__3nyWZ p span{color:#d01919;display:inline-block}.ParamForm_area__3nyWZ textarea{width:100%;resize:vertical;height:280px}.ParamForm_preview__30Q6m{list-style-type:none;max-height:300px;display:flex;flex-direction:column;flex-wrap:wrap}.ParamForm_preview__30Q6m li{background:#efefef;padding:.5em;margin:.25em;border-radius:15px;text-align:center}.ParamForm_condition__3K9Gi{display:flex;align-items:center}.ParamForm_condition__3K9Gi h3{margin:0;direction:ltr}
.paramManager_container__3pazI{display:flex}.paramManager_container__3pazI main{flex:1 1;margin:0 1em}
.ParamItem_item__-WAEo{display:flex;align-items:center;border-bottom:1px solid #cecece;margin:.5em 0;padding:.2em}.ParamItem_item__-WAEo .ParamItem_head__3-jx7{display:inline-block;width:40%;display:inline-flex;align-items:center}.ParamItem_item__-WAEo .ParamItem_head__3-jx7 p{margin:0 .5em;font-weight:700}.ParamItem_item__-WAEo .ParamItem_controlls__3SfX3{display:inline-block;white-space:nowrap;width:40%}.ParamItem_item__-WAEo .ParamItem_controlls__3SfX3 span{margin:0 .5em;display:inline-flex;align-items:center}.ParamItem_item__-WAEo .ParamItem_controlls__3SfX3 span label{margin:0 .5em}
.Stage_stage__3LaHX{border-radius:20px;box-shadow:2px 2px 5px #000;display:inline-flex;flex-direction:column;justify-content:space-between;min-width:350px;margin:1em}.Stage_stage__3LaHX .Stage_body__2Siv6{padding:.5em}.Stage_stage__3LaHX .Stage_header__30DrV{overflow:hidden;text-align:center;display:flex;align-items:center;justify-content:center;padding:.5em;border-top-left-radius:20px;border-top-right-radius:20px;background:#efefef}.Stage_stage__3LaHX .Stage_header__30DrV h3{margin:0 1em;font-style:italic}
.StageManager_items__25b7Y{display:flex;flex-wrap:wrap}.StageManager_add__3qfl6{display:inline-block;color:#aaa;font-size:3rem;padding:1em;display:flex;align-items:center;cursor:pointer}.StageManager_add__3qfl6:active{color:#777}
.ParamCondition_condition__20rVT{display:flex;align-items:center}.ParamCondition_condition__20rVT span{margin-right:.5em}
.ViewEditor_container__1eVRl{display:inline-flex;flex-direction:column;align-items:center}.ViewEditor_view__1Sm_v{position:relative;display:inline-block;margin:0 1em}.ViewEditor_bg__10wBF{max-height:400px}.ViewEditor_controlBar__1VexA{margin:0 5em}.ViewEditor_viewTitlePreview__24jeb{position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);font-size:5em;color:rgba(0,0,0,.5)}
.ViewProfileForm_container__1zwcu{display:flex;flex-direction:column;align-items:flex-start}.ViewProfileForm_conditions__2SH-1{display:inline-flex;flex-direction:column}.ViewProfileForm_condition__2FBPq{display:flex;align-items:center;margin:1em 0}
.ViewManager_container__UUOXa{display:flex}.ViewManager_container__UUOXa main{flex:1 1;margin:0 1em}
.PartCard_card__3WYg9{box-shadow:0 2.8px 2.2px rgba(0,0,0,.02),0 6.7px 5.3px rgba(0,0,0,.028),0 12.5px 10px rgba(0,0,0,.035),0 22.3px 17.9px rgba(0,0,0,.042),0 41.8px 33.4px rgba(0,0,0,.05);display:inline-flex;flex-direction:column;margin:1em;border-radius:15px;flex:1 0 auto;max-width:150px;overflow:hidden}.PartCard_card__3WYg9 img{max-height:150px}.PartCard_card__3WYg9 .PartCard_imageIcon__2AhcN{text-align:center;height:150px;width:150px;color:#efefef}.PartCard_card__3WYg9 a{text-align:center}
.Parts_filter__1CX0_{position:sticky;background:#fff;top:2em}.Parts_cards__RlmVZ{display:flex;flex-wrap:wrap}
.Login_form__1zX5V{display:inline-flex;border-radius:15px;box-shadow:0 2.8px 2.2px rgba(0,0,0,.02),0 6.7px 5.3px rgba(0,0,0,.028),0 12.5px 10px rgba(0,0,0,.035),0 22.3px 17.9px rgba(0,0,0,.042),0 41.8px 33.4px rgba(0,0,0,.05);padding:1em;overflow:hidden;margin:10em;background:#f7f7f7}
