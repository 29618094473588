.form {
  display: inline-flex;

  border-radius: 15px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05);
  padding: 1em;
  overflow: hidden;
  margin: 10em;
  background: #f7f7f7;
}
