.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  button {
    align-self: center;
  }
}
.main,
.media {
  & > div {
    margin: 0 1em;
  }
}
.media {
  margin: 1em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  //   border: 1px solid red;
  text-align: center;
}
