.container {
  display: flex;
  height: 300px;
  width: 50%;
}
.column {
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  width: 150px;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    text-align: center;
    background: #d71921;
    color: white;
    margin: 0;
  }
  .items {
    flex: 1;
    overflow-y: auto;
    padding: 0.25em;
    display: flex;
    flex-direction: column;
    span {
      font-weight: 700;

      margin: 0.25em;
      width: 50%;
      text-align: right;
    }
  }
  button {
    margin: 0 !important;
    border-radius: 0px !important;
  }
}
