.nav {
  top: 0;
  position: sticky;
  z-index: 100;
  background: #a70207;
  display: flex;
  justify-content: space-between;
}
.nav > div {
  background: white;
  align-self: center;
  padding: 0 0.5em;
  margin: 0 1em;
  box-shadow: 0px 0px 5px black;
  border-radius: 10px;
}
.nav nav {
  display: flex;
  justify-content: center;
  /* flex: 1; */
}
.nav li.navItem {
  list-style: none;
  padding: 10px 30px;
  color: #ddd;
  cursor: pointer;
  /* transition: 0.2s; */
}
.nav li:hover.navItem,
.nav a.active li {
  background: white;
  color: black;
}
.nav a {
  color: inherit;
  text-decoration: none;
}
